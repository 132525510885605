import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\"}],\"variableName\":\"proximaNova\"}");
;
import(/* webpackMode: "eager" */ "/app/public/images/favicon.webp");
;
import(/* webpackMode: "eager" */ "/app/src/contexts/app/index.ts");
;
import(/* webpackMode: "eager" */ "/app/src/contexts/currencies/CurrenciesContext.ts");
;
import(/* webpackMode: "eager" */ "/app/src/contexts/currencies/CurrenciesProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/contexts/i18n/index.ts");
;
import(/* webpackMode: "eager" */ "/app/src/styles/base.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
